$('.slider-top').slick({
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  appendArrows: true,
  arrows: true,
  prevArrow: $('.carousel-left-top'),
  nextArrow: $('.carousel-right-top'),
  adaptiveHeight: false,
  dots: true,
  variableWidth: true,
  width: 1920,
  variableHeight: true,
});

$('.carousel-latest-products').slick({
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  appendArrows: true,
  arrows: true,
  prevArrow: $('.carousel-latest-products-left'),
  nextArrow: $('.carousel-latest-products-right'),
  adaptiveHeight: false,
  dots: true,
  variableWidth: true,
  variableHeight: true,
});

$('.carousel-promotion').slick({
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  appendArrows: true,
  arrows: true,
  prevArrow: $('.carousel-promotion-products-left'),
  nextArrow: $('.carousel-promotion-products-right'),
  adaptiveHeight: false,
  dots: true,
  variableWidth: true,
  variableHeight: true
});
