import './style/custom.css';
import './js/carousel.js';
import './js/custom.js';
import './js/search-box.js';

import robotoBlack from './fonts/roboto/Roboto-Black.ttf';
import robotoBlackItalic from './fonts/roboto/Roboto-BlackItalic.ttf';
import robotoBold from './fonts/roboto/Roboto-Bold.ttf';
import robotoBoldItalic from './fonts/roboto/Roboto-BoldItalic.ttf';
import robotoItalic from './fonts/roboto/Roboto-Italic.ttf';
import robotoLight from './fonts/roboto/Roboto-Light.ttf';
import robotoLightItalic from './fonts/roboto/Roboto-LightItalic.ttf';
import robotoMedium from './fonts/roboto/Roboto-Medium.ttf';
import robotoMediumItalic from './fonts/roboto/Roboto-MediumItalic.ttf';
import robotoRegular from './fonts/roboto/Roboto-Regular.ttf';
import robotoThin from './fonts/roboto/Roboto-Thin.ttf';
import robotoThinItalic from './fonts/roboto/Roboto-ThinItalic.ttf';
import robotoCondensedBold from './fonts/roboto_condensed/RobotoCondensed-Bold.ttf';
import robotoCondensedBoldItalic from './fonts/roboto_condensed/RobotoCondensed-BoldItalic.ttf';
import robotoCondensedItalic from './fonts/roboto_condensed/RobotoCondensed-Italic.ttf';
import robotoCondensedLight from './fonts/roboto_condensed/RobotoCondensed-Light.ttf';
import robotoCondensedLightItalic from './fonts/roboto_condensed/RobotoCondensed-LightItalic.ttf';
import robotoCondensedRegular from './fonts/roboto_condensed/RobotoCondensed-Regular.ttf';

import cart from './img/cart.png';
import logo from './img/logo_sembella_SVG.svg';
import logoInverted from './img/logo_sembella_SVG_inverted.svg';

import koldry from './img/menu/webp/koldry.webp';
import lozka_kontynentalne from './img/menu/webp/lozka_kontynentalne.webp';
import materac_hybrydowy from './img/menu/webp/materac_hybrydowy.webp';
import materac_lateksowy from './img/menu/webp/materac_lateksowy.webp';
import materaz_piankowy from './img/menu/webp/materac_piankowy.webp';
import materac_sprezynowy from './img/menu/webp/materac_sprezynowy.webp';
import ochraniacze from './img/menu/webp/ochraniacze.webp';
import poduszki from './img/menu/webp/poduszki.webp';
import stelaz_bez_regulacji from './img/menu/webp/stelaz_bez_regulacji.webp';
import stelax_elektryczny from './img/menu/webp/stelaz_elektryczny.webp';
import topery from './img/menu/webp/topery.webp';

import avatar from './img/avatar.png';

import az from './img/filters/az.svg';
import dlon from './img/filters/dlon.svg';
import kwadraty from './img/filters/kwadraty.svg';
import kwadratySvg from './img/filters/kwadraty-svg.svg';
import materac from './img/filters/materac.svg';
import materacStrzalki from './img/filters/materac-strzalki.svg';

import dlon_choices from './img/dlon.png';
import man_choices from './img/man.png';
import m_ww_choices from './img/m_ww.png';

import koszyk_pink_120 from './img/koszyk_pink_120.png';
import koszyk_120 from './img/koszyk_120.png';
import logowanie_120 from './img/logowanie_120.png';
import logowanie_pink_120 from './img/logowanie_pink_120.png';
import przesylka_120 from './img/przesylka_120.png';
import przesylka_pink_120 from './img/przesylka_pink_120.png';
import platnosci_120 from './img/platnosci_120.png';
import platnosci_pink_120 from './img/platnosci_pink_120.png';
import podsumowanie_120 from './img/podsumowanie_120.png';
import podsumowanie_pink_120 from './img/podsumowanie_pink_120.png';

import przyodbiorze from './img/przyodbiorze.png';
import przelewy24 from './img/przelewy24.png';
import przelew from './img/przelew.png';

import lady from './img/lady.png';

import ct from './img/lozka-kontynentalne/CT.png';
import NPP from './img/lozka-kontynentalne/NPP.png';
import ns from './img/lozka-kontynentalne/NS.png';

import fun from './img/lozka-kontynentalne/fun.png';
import funCT from './img/lozka-kontynentalne/fun-CT.png';
import funNPP from './img/lozka-kontynentalne/fun-NPP.png';
import funNS from './img/lozka-kontynentalne/fun-NS.png';
import funWS from './img/lozka-kontynentalne/fun-WS.png';

import happy from './img/lozka-kontynentalne/happy.png';
import happyCT from './img/lozka-kontynentalne/happy-CT.png';
import happyNPP from './img/lozka-kontynentalne/happy-NPP.png';
import happyNS from './img/lozka-kontynentalne/happy-NS.png';
import happyWS from './img/lozka-kontynentalne/happy-WS.png';

import joy from './img/lozka-kontynentalne/joy.png';
import joyCT from './img/lozka-kontynentalne/joy-CT.png';
import joyNPP from './img/lozka-kontynentalne/joy-NPP.png';
import joyNS from './img/lozka-kontynentalne/joy-NS.png';
import joyWS from './img/lozka-kontynentalne/joy-WS.png';

import lucky from './img/lozka-kontynentalne/lucky.png';
import luckyCT from './img/lozka-kontynentalne/lucky-CT.png';
import luckyNPP from './img/lozka-kontynentalne/lucky-NPP.png';
import luckyNS from './img/lozka-kontynentalne/lucky-NS.png';
import luckyWS from './img/lozka-kontynentalne/lucky-WS.png';

import smile from './img/lozka-kontynentalne/smile.png';
import smileCT from './img/lozka-kontynentalne/smile-CT.png';
import smileNPP from './img/lozka-kontynentalne/smile-NPP.png';
import smileNS from './img/lozka-kontynentalne/smile-NS.png';
import smileWS from './img/lozka-kontynentalne/smile-WS.png';
