const sort = true;
$('li').mouseout(() => {
  $('li').removeClass('active2');
});

function formatAppliedPromotions(appliedPromotions) {
  let appliedPromotionsElement = '';
  $('#appliedPromotions').html('');
  if (appliedPromotions !== '[]') {
    $.each(appliedPromotions, (index, promotion) => {
      let promotionInfo = promotion.label;
      promotionInfo += promotion.description ? ` - ${promotion.description}` : '';
      appliedPromotionsElement += `<div class="ui blue label promotion_label" style="margin: 1rem 0;"><div class="row ui small sylius_catalog_promotion">${promotionInfo}</div></div>`;
    });
    $('#appliedPromotions').html(appliedPromotionsElement);
  }
}

// zmiana ceny w zależności od wybranej opcji produktu
const handleProductOptionsChange = function handleProductOptionsChange() {
  $('[name*="sylius_add_to_cart[cartItem][variant]"]').on('change', () => {
    let selector = '';

    $('#sylius-product-adding-to-cart select[data-option]').each((index, element) => {
      const select = $(element);
      const option = select.find('option:selected').val();
      selector += `[data-${select.attr('data-option')}="${option}"]`;
    });

    $('#sylius-product-adding-to-cart div[data-option]').each((index, element) => {
      const select = $(element);
      const option = select.find('input:checked').val();
      selector += `[data-${select.attr('data-option')}="${option}"]`;
    });

    const price = $('#sylius-variants-pricing').find(selector).attr('data-value');
    const promoPrice = $('#sylius-variants-pricing').find(selector).attr('data-promotion-price');
    const variantId = $('#sylius-variants-pricing').find(selector).attr('data-variantid');
    $('#product-price').attr('data-variant-id', variantId);
    $('#promo-product-price').attr('data-variant-id', variantId);

    const originalPrice = $('#sylius-variants-pricing').find(selector).attr('data-original-price');
    let appliedPromotions = $('#sylius-variants-pricing').find(selector).attr('data-applied_promotions');
    if (appliedPromotions !== undefined) {
      appliedPromotions = JSON.parse(appliedPromotions);
    }

    if (price !== undefined) {
      $('#product-price').text(price);
      $('#lowest-price').text(price);
      $('#promo-product-price').text(promoPrice);
      $('button[type=submit]').removeAttr('disabled');

      if (originalPrice !== undefined) {
        $('#product-original-price').css('display', 'inline').html(`<del>${originalPrice}</del>`);
      } else {
        $('#product-original-price').css('display', 'none');
      }

      formatAppliedPromotions(appliedPromotions);
    } else {
      $('#product-price').text($('#sylius-variants-pricing').attr('data-unavailable-text'));
      $('button[type=submit]').attr('disabled', 'disabled');
    }
  });
};

// zmiana ceny w zależności od wybranego wariantu produktu
const handleProductVariantsChange = function handleProductVariantsChange() {
  $('[name="sylius_add_to_cart[cartItem][variant]"]').on('change', (event) => {
    const priceRow = jquery(event.currentTarget).parents('tr').find('.sylius-product-variant-price');
    const price = priceRow.text();
    const originalPrice = priceRow.attr('data-original-price');
    let appliedPromotions = priceRow.attr('data-applied-promotions');
    if (appliedPromotions !== '[]') {
      appliedPromotions = JSON.parse(appliedPromotions);
    }

    $('#product-price').text(price);
    $('#lowest-price').text(price);
    formatAppliedPromotions(appliedPromotions);

    if (originalPrice !== undefined) {
      $('#product-original-price').css('display', 'inline').html(`<del>${originalPrice}</del>`);
    } else {
      $('#product-original-price').css('display', 'none');
    }
  });
};

$.fn.extend({
  variantPrices: function variantPrices() {
    if ($('#sylius-variants-pricing').length > 0) {
      handleProductOptionsChange();
    } else if ($('#sylius-product-variants').length > 0) {
      handleProductVariantsChange();
    }
  },
});

function transformToUpperCase(str, separators) {
  separators = separators || [' '];
  const regex = new RegExp(`(^|[${separators.join('')}])(\\w)`, 'g');
  return str.toLowerCase().replace(regex, (x) => x.toUpperCase());
}

window.transformToUpperCase = transformToUpperCase;

function telprint(tel) {
  const tel_clean = tel.replace(/\D/g, '');

  const two_first = parseInt((tel.replace(/\D/g, '')).slice(0, 2));
  if (two_first == 48) {
    two_first_v = parseInt((tel.replace(/\D/g, '')).slice(2, 4));
  } else {
    two_first_v = parseInt((tel.replace(/\D/g, '')).slice(0, 2));
  }
  mobile_numbers = [21, 45, 50, 51, 53, 57, 60, 66, 69, 72, 73, 78, 79, 88];

  if (mobile_numbers.includes(two_first_v)) {
    if (two_first == 48) {
      return `+${tel_clean.slice(0, 2)} ${tel_clean.slice(2, 5)} ${tel_clean.slice(5, 8)} ${tel_clean.slice(8, 11)}`;
    }
    return `${tel_clean.slice(0, 3)} ${tel_clean.slice(3, 6)} ${tel_clean.slice(6, 9)}`;
  }
  return `${tel_clean.slice(0, 2)} ${tel_clean.slice(2, 5)} ${tel_clean.slice(5, 7)} ${tel_clean.slice(7, 9)}`;
}

window.telprint = telprint;

let variantId = $('#product-price').attr('data-variant-id');

if ($('.error').length) {
  $('#sylius_shop_checkout_address').css('display', 'block');
  $('.column.register-choice-box').addClass('inactive');
}

$('.next.button').click(() => {
  $('#sylius_shop_checkout_address').css('display', 'block');
  $('.column.register-choice-box').addClass('inactive');
});

$('#add-to-basket-btn').click(() => {
  $('#reseller-message').removeClass('hidden');
});

/*
  ----------------------------------------------------------------------------------
  wyświetlenie wszystkich sklepów wraz z cenami, wywoływane podczas ładowania strony
  oraz przy zmianie województwa
  ----------------------------------------------------------------------------------
 */
function productVariantShow(sortVariant) {
  const provinceId = $('#province-select option:selected').val();
  variantId = $('#product-price').attr('data-variant-id');
  $('#retailers-shops').html(' ');
  $.ajax({
    url: '/retailers-shops',
    dataType: 'json',
    data: {
      provinceId, variantId, sortVariant,
    },
    beforeSend(xhr) {
      if (provinceId === '100') {
        $('#sylius-product-adding-to-cart').after(
          '<div class="hide-form"><h2>Proszę wybrać województwo</h2></div>',
        );
        xhr.abort();
      } else {
        $('#retailers-shops').append(
          '<div class="lds-ripple"><div></div><div></div></div>',
        );
        $('#sylius-product-adding-to-cart').after(
          '<div class="hide-form"><h2>Trwa pobieranie cen dystrybutorów...</h2></div>',
        );
      }
    },
    success(data) {
      if(data === 'brak')
      {
        $('.lds-ripple').remove();
        $('.hide-form').remove();
        $('#retailers-shops').append(
          `<h1>Brak sklepów dla wybranego województwa</h1>`,
        )
      }else{
        if (sortVariant === 'ASC') {
          data.sort((a, b) => {
            if (a.price === b.price) {
              return a.retailerShop.name.localeCompare(b.retailerShop.name);
            }
            return Number(a.price) < Number(b.price) ? -1 : 1;
          });
        } else {
          data.sort((a, b) => {
            if (a.price === b.price) {
              return a.retailerShop.name.localeCompare(b.retailerShop.name);
            }
            return Number(a.price) > Number(b.price) ? -1 : 1;
          });
        }
        $('.lds-ripple').remove();
        $('.hide-form').remove();
        $.each(data, (k, v) => {
          const priceBefore = v.price;
          const lowestPriceBefore = v.lowestPrice;

          const formattedPrice = `${priceBefore.toString().slice(0, (priceBefore.toString().length - 2))}, ${priceBefore.toString().slice(priceBefore.toString().length - 2)}`;
          const formattedLowestPrice = `${lowestPriceBefore.toString().slice(0, (lowestPriceBefore.toString().length - 2))}, ${lowestPriceBefore.toString().slice(lowestPriceBefore.toString().length - 2)}`;

          $('#retailers-shops').append(
            `<div class='two column row'>
            <div class="column">
            <h4>${v.retailerShop.name}</h4>
                <div class="two column row ui grid">
                    <div class="sixteen wide mobile nine wide computer column retailer-address">
                        <p>${v.retailerShop.street.toLowerCase()}<br/>${transformToUpperCase((v.retailerShop.city.toLowerCase()), ['-'])}<br/>tel. ${telprint(v.retailerShop.phone)}</p>
                    </div>
                    <div class="seven wide column working-hours">
                        <p>pn-pt: ${v.retailerShop.hours_week}<br/>sobota: ${v.retailerShop.hours_saturday}<br/>niedziela: ${v.retailerShop.hours_sunday}<br/></p>
                    </div>
                </div>
            </div>
            <div class="column add-to-basket-retailer-column">
                <button data-retailer="${v.retailerShop.id}" class="add-retailer-product mb-1 ui huge inverted semb-pink icon labeled button">
                     Dodaj do koszyka
                </button>
                <button
                      class="retailer-buy-button"
                      id="retailer-buy-button-${k}"
                      >
                      <img style="display: none;" />
                      <p>${formattedPrice} zł</p>
                </button>
            </div>
           </div>
            `,
          );

          // add promo
          if (v.promo === true) {
            $(`#retailer-buy-button-${k}`).addClass('download_button');
            $(`#retailer-buy-button-${k}`).attr('data-position', 'left center');
            $(`#retailer-buy-button-${k}`).after(`<i>Najniższa cena z ostatnich 30 dni przed obniżką: ${formattedLowestPrice} zł</i>`);
          }
          // finish
          $('.add-retailer-product').on('click', (event) => {
            dataLayer.push({ ecommerce: null });
            dataLayer.push({
              event: 'addToCart',
              ecommerce: {
                currencyCode: 'PLN',
                add: {
                  products: [{
                    price: formattedPrice,
                    variant: variantId,
                    quantity: 1,
                  }],
                },
              },
            });

            $.ajax({
              url: '/add-retailer-product',
              dataType: 'json',
              data: {
                variantId: $('#product-price').attr('data-variant-id'),
                retailer_id: $(event.currentTarget).data('retailer'),
              },
              success(data) {
                window.location.href = '/pl_PL/cart';
              },
              error(errorThrown, data) {
                $('#modal-retailer').modal({
                  allowMultiple: true,
                }).modal('show');
              },
            });
            event.stopPropagation();
            event.stopImmediatePropagation();
          });
        });
      }
    },
    error(xhr, textStatus, errorThrown) {
      console.log(errorThrown);
    },
  });
}

$('#sylius_add_to_cart_cartItem_variant_rozmiar_topper_wszystkie').on('click', () => {
  productVariantShow('ASC');
});
$('#sylius_add_to_cart_cartItem_variant_rozmiar_ochraniacz_secura').on('click', () => {
  productVariantShow('ASC');
});
$('#sylius_add_to_cart_cartItem_variant_rozmiar_materaca').on('click', () => {
  productVariantShow('ASC');
});

$('#sylius_add_to_cart_cartItem_variant_rozmiar_materaca_90_120_140_160_180').on('click', () => {
  productVariantShow('ASC');
});

$('#sylius_add_to_cart_cartItem_variant_rozmiar_materaca_90_140_160_180').on('click', () => {
  productVariantShow('ASC');
});

$('#sylius_add_to_cart_cartItem_variant_rozmiar_mat_80-180').on('click', () => {
  productVariantShow('ASC');
});

$('#sylius_add_to_cart_cartItem_variant_rozmiar_stelaza_80-90_140-180').on('click', () => {
  productVariantShow('ASC');
});

$('#sylius_add_to_cart_cartItem_variant_rozmiar_stelaza_80_90_140_160').on('click', () => {
  productVariantShow('ASC');
});

$('#sylius_add_to_cart_cartItem_variant_rozmiar_stelaza_70_80_90_140_160_180').on('click', () => {
  productVariantShow('ASC');
});

$('#sylius_add_to_cart_cartItem_variant_rozmiar_stelaza_wszystkie').on('click', () => {
  productVariantShow('ASC');
});

$('#sylius_add_to_cart_cartItem_variant_rozmiar-koldry').on('click', () => {
  productVariantShow('ASC');
});

$('#sylius_add_to_cart_cartItem_variant_twardosc_materaca_h2-3').on('click', () => {
  productVariantShow('ASC');
});

$('#sylius_add_to_cart_cartItem_variant_twardosc_materaca_h2-4').on('click', () => {
  productVariantShow('ASC');
});

$('#sylius_add_to_cart_cartItem_variant_rozmiar_mat_80_90_100_120').on('click', () => {
  productVariantShow('ASC');
});

$('#sylius_add_to_cart_cartItem_variant_poduszka').on('click', () => {
  productVariantShow('ASC');
});

$('#sort_price').click(() => {
  $('#sort_price_arrow').toggleClass('up', 'down');
  if ($('#sort_price').attr('data-sort') === 'DESC') {
    $('#sort_price').attr('data-sort', 'ASC');
    productVariantShow('ASC');
  } else {
    $('#sort_price').attr('data-sort', 'DESC');
    productVariantShow('DESC');
  }
});

// Menu mobilne chowanie

$('.menu_button').click(() => {
  $('#main-menu ul').slideToggle();
});
$('.filter_button').click(() => {
  $('.inside_filter').slideToggle();
});

$('#main-menu > ul li.item').children('.sub_menu').mouseleave(function () {
  $('#main-menu > ul li.item').removeClass('active2');
  const height_sub = $('.sub_menu.show > ul').height();
  const height_sub_out = $('.sub_menu.show > ul').outerHeight();
  let ul_height = 0;
  for (let i = 0; i < $('.sub_menu.show > ul > li').length; i++) {
    ul_height += $('.sub_menu.show > ul > li').eq(i).height();
  }
  const height = height_sub_out - height_sub + ul_height;
  $(this).slideUp(function () {
    $(this).removeClass('show show_sub show_sub_sub');
    $(this).find('.sub_sub_menu').removeClass('show');
    $(this).find('.sub_sub_menu .item').css('opacity', 0);
    $(this).find('.sub_sub_sub_menu').removeClass('show');
    $(this).find('.sub_sub_sub_menu .item').css('opacity', 0);
    $(this).css('height', height);
    $(this).find('a').css('font-family', 'Roboto');
  });
});
$('#main-menu > ul li.item').mouseenter(function () {
  $(this).siblings().removeClass('active2');
  $(this).siblings().children('.sub_menu').slideUp(function () {
    $(this).removeClass('show');
  });
  if ($(this).children('.sub_menu').hasClass('show')) {

  } else {
    $(this).children('.sub_menu').addClass('show').css('display', 'none')
      .slideDown();
    $(this).addClass('active2');
  }
});
$('.sub_menu > ul > li.item').mouseenter(function () {
  $(this).siblings().children('a').css('font-family', 'Roboto');

  $(this).children('a').css('font-family', 'Roboto-Bold');

  $(this).siblings().find('.sub_sub_sub_menu').removeClass('show');
  $(this).siblings().find('.sub_sub_sub_menu .item').css('opacity', 0);
});

$('#main-menu ul li.item .sub_menu > ul > li').mouseenter(function () {
  const height_sub = $('.sub_menu.show > ul').height();
  const height_sub_out = $('.sub_menu.show > ul').outerHeight();
  let ul_height = 0;
  for (let i = 0; i < $('.sub_menu.show > ul > li').length; i++) {
    ul_height += $('.sub_menu.show > ul > li').eq(i).height();
  }
  const height = height_sub_out - height_sub + ul_height;
  const height_sub_sub = $(this).find('.sub_sub_menu').height();
  if (height_sub_sub > height) {
    $('.sub_menu.show').css('height', height_sub_sub);
  } else {
    $('.sub_menu.show').css('height', height);
  }

  $('#main-menu ul li.item .sub_menu').addClass('show_sub');
  setTimeout(
    () => {
      $('.sub_sub_menu.show > ul > .item').animate({
        opacity: 1,
      });
    }, 200,
  );
});
$('#main-menu ul li.item .sub_sub_menu > ul > li').mouseenter(function () {
  const height_sub = $('.sub_menu.show > ul').height();
  const height_sub_out = $('.sub_menu.show > ul').outerHeight();
  let ul_height = 0;
  for (let i = 0; i < $('.sub_menu.show > ul > li').length; i++) {
    ul_height += $('.sub_menu.show > ul > li').eq(i).height();
  }
  const height = height_sub_out - height_sub + ul_height;
  const height_sub_sub_sub = $(this).find('.sub_sub_sub_menu').height();
  if (height_sub_sub_sub > height) {
    $('.sub_menu.show').css('height', height_sub_sub_sub);
  } else {
    $('.sub_menu.show').css('height', height);
  }
  $('#main-menu ul li.item .sub_menu').addClass('show_sub_sub');
  setTimeout(
    () => {
      $('.sub_sub_sub_menu.show > ul > .item').animate({
        opacity: 1,
      });
    }, 200,
  );
});

$('#main-menu ul li.item .sub_menu > ul li.item ').mouseenter(function () {
  $(this).siblings().children('.sub_sub_menu').removeClass('show');
  if ($(this).children('.sub_sub_menu').hasClass('show')) {

  } else {
    $(this).children('.sub_sub_menu').addClass('show');
  }
});

$('#main-menu ul li.item .sub_menu > ul li.item > .sub_sub_menu > ul li.item').mouseenter(function () {
  $(this).siblings().children('.sub_sub_sub_menu').removeClass('show');
  $(this).siblings().children('.sub_sub_sub_menu').find('li.item')
    .css('opacity', 0);
  $(this).siblings().find('a').css('font-family', 'Roboto');
  $(this).find('a').css('font-family', 'Roboto-Bold');
  if ($(this).children('.sub_sub_sub_menu').hasClass('show')) {

  } else {
    $(this).children('.sub_sub_sub_menu').addClass('show');
  }
});

$('#province-select').on('change', function () {
  if ($(this).val() === 'Wybierz województwo aby zobaczyć sklepy') {
    $('#sort_price').hide();
    $('#retailers-shops').html(' ');
  } else {
    productVariantShow('ASC');
    $('#sort_price').show();
  }
});

const readMore = false;

$('.read-more').click(function () {
  const boxDescription = $(this).closest('.box-description');

  boxDescription.toggleClass('full');

  if (boxDescription.hasClass('full')) {
    $(this).text('Zwiń opis');
  } else {
    $(this).text('Czytaj więcej...');
  }
});

$(document).ready(() => {
  const url_product = window.location.href;

  /* if (url_product.match('produkt')) { // console.log('Matched :'+url_product);
    productVariantShow('ASC');
    $('#sort_price').show();
  } */
});

$('.slick-slide div:first-child').addClass('slick-div');
$('.carousel-item').removeClass('slick-div');
$('.dimmer').removeClass('slick-div');
$('.to_style_height').removeClass('slick-div');
$('.content').removeClass('slick-div');
$('.center').removeClass('slick-div');
$('.inverted').removeClass('slick-div');

$('map[name=mapname]').on('mouseenter', 'area', function () {
  $('#map').attr('src', $(this).data('src'));
}).on('mouseleave', 'area', () => {
  $('#map').attr('src', $('#map').data('src'));
});

$('map[name=mapname]').on('click', 'area', function (e) {
  e.preventDefault();
  $('#map').data('src', $(this).data('src'));
  $('.sektor').hide();
  $($(this).attr('href')).show();
});

$('map[name=mapname-xs]').on('mouseenter', 'area', function () {
  $('#map2').attr('src', $(this).data('src'));
}).on('mouseleave', 'area', () => {
  $('#map2').attr('src', $('#map2').data('src'));
});

$('map[name=mapname-xs]').on('click', 'area', function (e) {
  e.preventDefault();
  $('#map2').data('src', $(this).data('src'));
  $('.sektor').hide();
  $($(this).attr('href')).show();
});

/* accepted-terms, accepted-privacy-policy, accepted-free-transport-promotion, accepted-sells-terms */

$('#accepted-terms').parent().parent().append('<a class="accepted-info" href="/media/file/regulamin_sklepu.pdf"><i class="info circle icon link" data-content="Przeczytaj"></a>');
$('#accepted-privacy-policy').parent().parent().append('<a class="accepted-info" href="/pl_PL/contents/polityka-prywatnosci"><i class="ui info circle icon" data-content="Przeczytaj"></a>');
$('#accepted-free-transport-promotion').parent().parent().append('<a class="accepted-info" href="/pl_PL/contents/promocja-transport-gratis"><i class="ui info circle icon" data-content="Przeczytaj"></a>');
$('#accepted-sells-terms').parent().parent().append('<a class="accepted-info" href="/media/file/ogolne_warunki_sprzedazy.pdf"><i class="ui info circle icon" data-content="Przeczytaj"></a>');

// eslint-disable-next-line no-undef
$(document).ready(() => {
  const totalLength = $('.retailer-policy').length;
  $('.retailer-policy').click(() => {
    if ($('.checked').length === totalLength) {
      $('#checkout-button').removeClass('disabled');
    } else {
      $('#checkout-button').addClass('disabled');
    }
  });
});
