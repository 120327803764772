(function ($) {
  'use strict';

  $(document).ready(function () {
    var $searchBox = $(".search");

    var $productSelect = $searchBox.find(".search__select--product");
    var $productSelectFilter = $searchBox.find("#select-product");

    var $productWrapperDiv = $searchBox.find(".search__product-wrapper");

    var $productTypeWrapperDiv = $searchBox.find(".search__type-wrapper");
    var $productTypeSelect = $productTypeWrapperDiv.find(".search__select--type");

    var $hardnessWrapperDiv = $searchBox.find(".search__hardness-wrapper");
    var $hardnessSelect = $hardnessWrapperDiv.find(".search__select--hardness");
    var $hardnessSelectFilter = $hardnessWrapperDiv.find("#select-hardness");

    var $sizeWrapperDiv = $searchBox.find(".search__size-wrapper");
    var $sizeSelect = $sizeWrapperDiv.find(".search__select--size");

    var $stelazSizeWrapperDiv = $searchBox.find(".search__stelaz-size-wrapper");
    var $stelazSizeSelect = $stelazSizeWrapperDiv.find(".search__select--stelaz-size");


    var MATERACE_ALL = 1;
    var MATERACE_SPREZYNOWE = 2;
    var MATERACE_PIANKOWE = 3;
    var MATERACE_LATEKSOWE = 4;
    var MATERACE_HYBRYDOWE = 5;
    var MATERACE_DZIECIECE = 6;

    var STELAZE_ALL = 7;
    var STELAZE_ELEKTRYCZNE = 8;
    var STELAZE_MECHANICZNE = 9;
    var STELAZE_BEZ_REGULACJI = 10;

    var KOLDRY_ALL = 11;
    var KOLDRY_PODUSZKI = 12;
    var KOLDRY_KOLDRY = 13;

    var DODATKI_ALL = 14;
    var DODATKI_OCHRANIACZE = 15;
    var DODATKI_NAKLADKI = 16;

    var RODZAJ_ALL = 0;
    var RODZAJ_BONNELLOWE = 1;
    var RODZAJ_KIESZENIOWE = 2;
    var RODZAJ_WYSOKOELASTYCZNE = 3;
    var RODZAJ_TERMOELASTYCZNE = 4;
    var RODZAJ_DZIECIECE = 5;

    $productSelect.change(function () {
      var selectedProduct = $productSelect.val();
      var selectedProductFilter = parseInt($productSelectFilter.val());

      $productTypeSelect.find("option").addClass("disabled").attr("disabled", "disabled");
      $hardnessWrapperDiv.addClass("disabled");
      $hardnessSelect.attr("disabled", "disabled");
      $sizeWrapperDiv.addClass("disabled");
      $sizeSelect.attr("disabled", "disabled");
      $('.search__hardness-wrapper').find('.selection').addClass("disabled");
      $('.search__size-wrapper').find('.selection').addClass("disabled");

      if (selectedProductFilter <= MATERACE_HYBRYDOWE) {
        $('.search__hardness-wrapper').find('.selection').removeClass("disabled");
        $('.search__size-wrapper').find('.selection').removeClass("disabled");
      }

      if (selectedProductFilter >= MATERACE_DZIECIECE && selectedProduct <= STELAZE_BEZ_REGULACJI) {
        $('.search__size-wrapper').find('.selection').removeClass("disabled");
      }

      if (selectedProductFilter >= KOLDRY_ALL && selectedProductFilter <= DODATKI_NAKLADKI) {
        $('.search__hardness-wrapper').find('.selection').addClass("disabled");
        $('.search__size-wrapper').find('.selection').addClass("disabled");
      }


      if (selectedProduct <= MATERACE_HYBRYDOWE) {
        $hardnessWrapperDiv.find("option.child").removeAttr("disabled").removeClass("disabled");
        $hardnessSelect.removeAttr("disabled");
        $sizeSelect.removeAttr("disabled");
        $sizeWrapperDiv.find("option.non-child").removeAttr("disabled").removeClass("disabled");
      }

      if (selectedProduct >= MATERACE_DZIECIECE && selectedProduct <= STELAZE_BEZ_REGULACJI) {
        $sizeSelect.removeAttr("disabled");
        $sizeWrapperDiv.find("option.non-child").removeAttr("disabled").removeClass("disabled");
      }

    });

    /*$productSelect.change(function () {
      var selectedProduct = $productSelect.val();
      $productTypeSelect.find("option").addClass("disabled").attr("disabled", "disabled");
      if (selectedProduct === MATERACE_SPREZYNOWE) {
        $productTypeSelect.find("option.only-1").removeClass("disabled").removeAttr("disabled");
        $productTypeSelect.val(0);
        $productTypeWrapperDiv.removeClass("disabled");
      } else if (selectedProduct === MATERACE_PIANKOWE) {
        $productTypeSelect.find("option.only-2").removeClass("disabled").removeAttr("disabled");
        $productTypeSelect.val(0);
        $productTypeWrapperDiv.removeClass("disabled");
      } else {
        $productTypeWrapperDiv.addClass("disabled");
      }
      //refreshSelectors();
    });*/
    //$productTypeSelect.change(refreshSelectors);
    //refreshSelectors();

    function refreshSelectors() {
      var selectedProduct = $productSelect.val();
      var selectedType = $productTypeSelect.val();

      if (selectedProduct <= MATERACE_HYBRYDOWE) {
        $hardnessWrapperDiv.removeClass("disabled");
        $hardnessSelect.removeAttr("disabled");
        if (selectedProduct === MATERACE_ALL) {
          $hardnessWrapperDiv.find("option.child").removeAttr("disabled").removeClass("disabled");
        } else if (selectedProduct === MATERACE_SPREZYNOWE && selectedType === RODZAJ_BONNELLOWE) {
          $hardnessWrapperDiv.find("option.non-bonnellowe").attr("disabled", "disabled").addClass("disabled");
          $hardnessWrapperDiv.find("option.bonnellowe").removeAttr("disabled").removeClass("disabled");
        } else if (selectedProduct === MATERACE_LATEKSOWE) {
          $hardnessWrapperDiv.find("option.non-latex").attr("disabled", "disabled").addClass("disabled");
          $hardnessWrapperDiv.find("option.latex").removeAttr("disabled").removeClass("disabled");
        } else {
          $hardnessWrapperDiv.find("option.child").attr("disabled", "disabled").addClass("disabled");
        }
      } else {
        $hardnessWrapperDiv.addClass("disabled");
        $hardnessSelect.attr("disabled", "disabled");
      }

      if (selectedProduct <= MATERACE_DZIECIECE) {
        $sizeWrapperDiv.removeClass("disabled");
        $sizeSelect.removeAttr("disabled");
        if (selectedProduct === MATERACE_DZIECIECE) {
          $sizeWrapperDiv.find("option.non-child").attr("disabled", "disabled").addClass("disabled");
          $sizeWrapperDiv.find("option.child").removeAttr("disabled").removeClass("disabled");
        } else {
          $sizeWrapperDiv.find("option.child").attr("disabled", "disabled").addClass("disabled");
          $sizeWrapperDiv.find("option.non-child").removeAttr("disabled").removeClass("disabled");
        }
      } else {
        $sizeWrapperDiv.addClass("disabled");
        $sizeSelect.attr("disabled", "disabled");
      }

      if (selectedProduct >= STELAZE_ALL && selectedProduct <= STELAZE_BEZ_REGULACJI) {
        $stelazSizeWrapperDiv.removeClass("disabled");
        $stelazSizeSelect.removeAttr("disabled");
      } else {
        $stelazSizeWrapperDiv.addClass("disabled");
        $stelazSizeSelect.attr("disabled");
      }
    }


    $(".search .search__button").click(function () {
      var selectedProduct = parseInt($productSelect.val());
      var link = searchUrl;

      switch (selectedProduct) {
        case MATERACE_ALL:
          link += "taxon[kategoria]=materace";
          break;
        case MATERACE_SPREZYNOWE:
          link += "taxon[kategoria]=materace/materace-sprezynowe";
          break;
        case MATERACE_PIANKOWE:
          link += "taxon[kategoria]=materace/materace-piankowe";
          break;
        case MATERACE_LATEKSOWE:
          link += "taxon[kategoria]=materace/materace-lateksowe";
          break;
        case MATERACE_HYBRYDOWE:
          link += "taxon[kategoria]=materace/materace-hybrydowe";
          break;
        case MATERACE_DZIECIECE:
          link += "taxon[kategoria]=materace/materace-dzieciece";
          break;
        case STELAZE_ALL:
          link += "taxon[kategoria]=stelaze";
          break;
        case STELAZE_ELEKTRYCZNE:
          link += "taxon[kategoria]=stelaze/regulacja-elektryczna";
          break;
        case STELAZE_MECHANICZNE:
          link += "taxon[kategoria]=stelaze/regulacja-mechaniczna";
          break;
        case STELAZE_BEZ_REGULACJI:
          link += "taxon[kategoria]=stelaze/brak-regulacji";
          break;
        case KOLDRY_ALL:
          link += "taxon[kategoria]=koldry-i-poduszki";
          break;
        case KOLDRY_PODUSZKI:
          link += "taxon[kategoria]=koldry-i-poduszki/poduszki";
          break;
        case KOLDRY_KOLDRY:
          link += "taxon[kategoria]=koldry-i-poduszki/koldry";
          break;
        case DODATKI_ALL:
          link += "taxon[kategoria]=dodatki";
          break;
        case DODATKI_OCHRANIACZE:
          link += "taxon[kategoria]=dodatki/ochraniacze";
          break;
        case DODATKI_NAKLADKI:
          link += "taxon[kategoria]=dodatki/nakladki-toppery";
          break;
      }

      if (selectedProduct === MATERACE_SPREZYNOWE || selectedProduct === MATERACE_PIANKOWE) {
        var selectedProductType = $productTypeSelect.val();
        switch (parseInt(selectedProductType)) {
          case RODZAJ_BONNELLOWE:
            link += "/bonellowe";
            break;
          case RODZAJ_KIESZENIOWE:
            link += "/kieszeniowe";
            break;
          case RODZAJ_WYSOKOELASTYCZNE:
            link += "/wysokoelastyczne";
            break;
          case RODZAJ_TERMOELASTYCZNE:
            link += "/termoelastyczne";
            break;
          case RODZAJ_DZIECIECE:
            link += "/dzieciece";
            break;
        }
      }

      if (selectedProduct <= MATERACE_LATEKSOWE) {
        var selectedHardness = $hardnessSelect.val();
        if (selectedHardness) {
          link += "&taxon[twardosc]=" + selectedHardness;
        }
      }

      if (selectedProduct <= MATERACE_DZIECIECE) {
        var selectedSize = $sizeSelect.val();
        if (selectedSize) {
          link += "&taxon[rozmiar]=" + selectedSize;
        }
      }

      if (selectedProduct >= STELAZE_ALL && selectedProduct <= STELAZE_BEZ_REGULACJI) {
        var stelazSelectedSize = $stelazSizeSelect.val();
        if (stelazSelectedSize) {
          link += "&taxon[rozmiar]=" + stelazSelectedSize;
        }
      }

      window.location = link;
    });

    $("#products-search-button").click(function () {
      var selectedProduct = parseInt($productSelectFilter.val());
      var link = searchUrl;

      switch (selectedProduct) {
        case MATERACE_ALL:
          link += "taxon[kategoria]=materace";
          break;
        case MATERACE_SPREZYNOWE:
          link += "taxon[kategoria]=materace/materace-sprezynowe";
          break;
        case MATERACE_PIANKOWE:
          link += "taxon[kategoria]=materace/materace-piankowe";
          break;
        case MATERACE_LATEKSOWE:
          link += "taxon[kategoria]=materace/materace-lateksowe";
          break;
        case MATERACE_HYBRYDOWE:
          link += "taxon[kategoria]=materace/materace-hybrydowe";
          break;
        case MATERACE_DZIECIECE:
          link += "taxon[kategoria]=materace/materace-dzieciece";
          break;
        case STELAZE_ALL:
          link += "taxon[kategoria]=stelaze";
          break;
        case STELAZE_ELEKTRYCZNE:
          link += "taxon[kategoria]=stelaze/regulacja-elektryczna";
          break;
        case STELAZE_MECHANICZNE:
          link += "taxon[kategoria]=stelaze/regulacja-mechaniczna";
          break;
        case STELAZE_BEZ_REGULACJI:
          link += "taxon[kategoria]=stelaze/brak-regulacji";
          break;
        case KOLDRY_ALL:
          link += "taxon[kategoria]=koldry-i-poduszki";
          break;
        case KOLDRY_PODUSZKI:
          link += "taxon[kategoria]=koldry-i-poduszki/poduszki";
          break;
        case KOLDRY_KOLDRY:
          link += "taxon[kategoria]=koldry-i-poduszki/koldry";
          break;
        case DODATKI_ALL:
          link += "taxon[kategoria]=dodatki";
          break;
        case DODATKI_OCHRANIACZE:
          link += "taxon[kategoria]=dodatki/ochraniacze";
          break;
        case DODATKI_NAKLADKI:
          link += "taxon[kategoria]=dodatki/nakladki-toppery";
          break;
      }

      if (selectedProduct === MATERACE_SPREZYNOWE || selectedProduct === MATERACE_PIANKOWE) {
        var selectedProductType = $productTypeSelect.val();
        switch (parseInt(selectedProductType)) {
          case RODZAJ_BONNELLOWE:
            link += "/bonellowe";
            break;
          case RODZAJ_KIESZENIOWE:
            link += "/kieszeniowe";
            break;
          case RODZAJ_WYSOKOELASTYCZNE:
            link += "/wysokoelastyczne";
            break;
          case RODZAJ_TERMOELASTYCZNE:
            link += "/termoelastyczne";
            break;
          case RODZAJ_DZIECIECE:
            link += "/dzieciece";
            break;
        }
      }

      if (selectedProduct <= MATERACE_LATEKSOWE) {
        var selectedHardness = $hardnessSelectFilter.val();
        if (selectedHardness) {
          link += "&taxon[twardosc]=" + selectedHardness;
        }
      }

      if (selectedProduct <= MATERACE_DZIECIECE) {
        var selectedSize = $sizeSelect.val();
        if (selectedSize) {
          link += "&taxon[rozmiar]=" + selectedSize;
        }
      }

      if (selectedProduct >= STELAZE_ALL && selectedProduct <= STELAZE_BEZ_REGULACJI) {
        var stelazSelectedSize = $stelazSizeSelect.val();
        if (stelazSelectedSize) {
          link += "&taxon[rozmiar]=" + stelazSelectedSize;
        }
      }

      window.location = link;
    });
  });
})(jQuery);
